.banner {
    background-color: #b22222; /* Change to your desired color */
    color: white;
    text-align: center;
    padding: 10px 0; /* Space around the text */
    font-size: 1.5rem; /* Larger text for visibility */
    position: relative; /* Positioning for curved edges */
    overflow: hidden; /* Ensure no overflow beyond the border */
    border-radius: 25px; /* Curved edges */
    margin: 20px; /* Space around the banner */
    animation: slide-in 5s infinite; /* Slide-in animation */
  }
  
  @keyframes slide-in {
    0% { transform: translateX(100%); }
    20% { transform: translateX(0); }
    80% { transform: translateX(0); }
    100% { transform: translateX(-100%); }
  }
  
  /* Optional: Style for mobile responsiveness */
  @media (max-width: 600px) {
    .banner {
      font-size: 1.2rem; /* Adjust font size for smaller screens */
      padding: 8px 0; /* Adjust padding for smaller screens */
    }
  }
  