/* Home.css */
.home-container {
    padding: 20px;
    background-color: #f9f9f9; /* Light background for contrast */
  }
  
  .mission-section,
  .expertise-section {
    display: flex;
    margin-bottom: 40px; /* Space between sections */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden; /* Prevent image overflow */
  }
  
  .mission-image-container,
  .expertise-image-container {
    flex: 1;
    overflow: hidden;
  }
  
  .mission-image,
  .expertise-image {
    width: 30%;
    height: auto; /* Responsive image */
  }
  
  .mission-text,
  .expertise-text {
    flex: 1;
    padding: 20px; /* Space around text */
  }
  
  h1 {
    color: #B22222; /* Maroonish red color */
  }
  
  .collapsible-section {
    margin: 20px 0; /* Space between collapsible sections */
  }
  
  .collapsible-title {
    font-size: 1.5em;
    cursor: pointer; /* Indicate that the title is clickable */
    transition: color 0.3s; /* Smooth color transition */
  }
  
  .collapsible-title:hover {
    color: #B22222; /* Highlight on hover */
  }
  
  .collapsible-content {
    margin-top: 10px;
    font-size: 1em;
    color: #333; /* Darker text for description */
    padding: 10px; /* Add padding to the content */
    border: 1px solid #ccc; /* Light border around the content */
    border-radius: 5px; /* Rounded corners */
  }
  